import React from "react";

const Privacy = () => {
  return (
    <div className="container py-40 mx-auto px-4">
      <h1 className="text-left font-[400] text-[24px] leading-[28px] text-[#1F1F39] font-poppins pb-[50px]">
        Privacy Policy
      </h1>
      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px]">
        Teenyfy respects your privacy and is committed to protecting your
        privacy
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        This Privacy Policy applies to the information we obtained through your
        use of https://www.teenyfy.com/ ("the Website" or "Teenyfy"). By using
        the Website, you consent to having your personal information that you
        provide directly to us or that we collect through your use of the
        Website as described in this Privacy Policy.
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Please read the following thoroughly to understand our policies and
        practices regarding your information collected through the Website,
        through our marketing activities and how we will treat it. If you do not
        agree with the below policies and practices, you have the choice to not
        to browse our Website or fill out a form on our Website.
      </p>
      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        What information does the Website collect and how is it used?
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We collect information that, alone or in combination with other data,
        could be used to identify you ("Personal Information"). Some of the
        Information we collect is stored in a manner that cannot be linked back
        to you ("Non-Personal Information").
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Contact Information: We collect the following Personal Information
        through our Website when you share that information with us to ask us to
        contact you: first and last name, email, and phone. We process this
        information in order to contact you, to respond to your inquiries and to
        send you marketing communications we believe may be relevant to you.
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Automatically Collected Data: Our servers automatically collect
        additional information when you access or use the Website and record it
        in log files. This log data may include the IP address, the date and
        time of use, browser type and settings, information about browser
        configuration, language preferences, and cookie data.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Does Teenyfy disclose any Personal Information to third parties?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We do not sell, trade, or otherwise transfer to outside parties your
        Personal Information.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Does the Website use cookies?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Yes. A cookie is a small piece of data sent from a website and stored in
        the user's web browser while the user is browsing. Cookies enable us to
        recognize your browser, and capture and remember certain information. We
        use cookies to compile aggregate data about site traffic and site
        interaction so that we can offer better site experiences and tools in
        the future. We may contract with third-party service providers to assist
        us in better understanding our site visitors. These service providers
        are not permitted to use the information collected on our behalf except
        to help us conduct and improve our business.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Does your Website engage in online advertising and tracking?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Yes. We may use cookies for retargeting online advertisements.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Does your website use Google Analytics?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Yes, <span className="font-[600]">Teenyfy</span> uses Google Analytics.
        To view how Google collects and processes data, follow this link:
        www.google.com/policies/privacy/partners/.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Can I opt-out of Cookies?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        No,You need to agree to collect the cookies.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Can I opt-out of marketing communications?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        If you wish to no longer receive marketing communications from us, you
        may opt-out by clicking the "unsubscribe" link found within{" "}
        <span className="font-[600]">Teenyfy</span> emails. If you are located
        in the European Economic Area (EEA), we will only send you marketing
        information if you consent to us doing so at the time you provide your
        Personally Identifiable Information to us through our Website or any
        point thereafter.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Does your Website link to third party sites?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Our website may contain links to other sites that are not owned or
        controlled by <span className="font-[600]">Teenyfy</span>. Please be
        aware <span className="font-[600]">Teenyfy</span> is not responsible for
        the privacy practices or policies of others. We encourage you to consult
        and read the privacy policies of the other sites. This privacy policy
        applies only to information collected by our website
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        How does Teenyfy keep Personal Information secure?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        <span className="font-[600]">Teenyfy</span> takes reasonable
        administrative, technical and physical measures to safeguard your
        Personal Information against loss, theft, and unauthorised access, use,
        disclosure, alteration, or destruction. Unfortunately, no data
        transmission or storage system can be guaranteed to be 100% secure. If
        you have any questions or concerns, please contact us on following link{" "}
        <a href="/contact-us" className="text-dark-green">
          https://www.teenyfy.com/contact-us{" "}
        </a>
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        How long does Teenyfy retain Personal Information ?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We store Personal Information associated with you until you ask us to
        delete it. You can request deletion of your Personal Information at any
        time by contacting us at{" "}
        <a href="mailto:info@teenyfy.com" className="text-dark-green">
          info@teenyfy.com
        </a>{" "}
        . Please note that it may take a bit of time to delete your Personal
        Information, and we may preserve it for legal reasons or to prevent
        harm, including as described in the "Does{" "}
        <span className="font-[600]">Teenyfy</span> disclose any Personal
        Information to third parties?" section.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        What rights do EEA visitors have under GDPR, and how can I exercise
        them?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        <span className="font-[600]">Teenyfy</span> processes personal data as
        described in this policy. We rely on your consent to send promotional
        emails and to place marketing cookies on your devices. In some cases,{" "}
        <span className="font-[600]">Teenyfy</span> may process personal data
        pursuant to legal obligation or to protect your vital interests or those
        of another person.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Individuals located in the European Economic Area (EEA) have certain
        rights in respect to their personal information, including the right to
        access, correct, or delete personal data we process through your use of
        the site. If you're a user based in the EEA, you can
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Have your information corrected or deleted by contacting us at
        info@teenyfy.com.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Object to us processing your information. You can ask us to stop using
        your information, including when we use your information to send you
        marketing emails. We only send marketing communications to users located
        in the EEA with your prior consent, and you may withdraw your consent at
        any time by clicking the "unsubscribe" link found within{" "}
        <span className="font-[600]">Teenyfy</span> emails. Please note you may
        continue to receive administrative account-related information, even if
        you unsubscribe from marketing emails.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Complaint to a regulator. If you're based in the EEA and think that we
        haven't complied with data protection laws, you have a right to lodge a
        complaint with your local supervisory authority.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        California Online Privacy Protection Act Compliance
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Because we value your privacy we have taken the necessary precautions to
        be in compliance with the California Online Privacy Protection Act. We
        will not distribute your personal information to outside parties without
        your consent.
      </p>
      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Children's Online Privacy Protection Act Compliance
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We do not collect any information from anyone under 5 years of age. Our
        website, products, and services are all directed at adults.
      </p>
      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        Changes to Our Privacy Policy
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We may change this privacy policy from time to time. If we decide to
        change our privacy policy, we will post those changes on this page.
      </p>
    </div>
  );
};

export default Privacy;
