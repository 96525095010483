import React from "react";
import Privacy from "../components/privacy/privacy";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const index = () => {
  return (
    <Layout>
      <Seo slug="/privacy-policy/" />
      <Privacy />
    </Layout>
  );
};

export default index;
